import { styled } from '@mui/system'
import BHButton from '@bluheadless/ui/src/molecules/button'
import { Stack as MuiStack } from '@mui/material'

const shouldForwardProp = (prop) => !['bgColor', 'count', 'index'].includes(prop)

const ImageWrapper = styled('div', { shouldForwardProp })`
	align-items: center;
	display: flex;
	justify-content: center;
	overflow: hidden;
	position: relative;
	transition: transform 1s;
	width: calc(100% / ${({ count }) => count});
	z-index: 1;
	&.active,
	&.animated-after {
		transform: translateX(calc(100% * ${({ count, index }) => count - index}));
	}
`

const CtaWrapper = styled('div', { shouldForwardProp })`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	left: ${({ count, index }) => `calc(100% / ${count} * ${index})`};
	position: absolute;
	top: 0;
	transition: all 1s;
	width: calc(100% / ${({ count }) => count});
	z-index: 3;
	&:not(.active) {
		cursor: pointer;
	}
	&.active,
	&.animated-before,
	&.animated-after {
		> button {
			backdrop-filter: none;
			background-color: transparent;
			font-size: ${({ theme }) => theme.typography.pxToRem(18)};
			letter-spacing: -0.36px;
			min-width: 0;
			padding: 0;
		}
	}
	&.animated-after,
	&.animated-before {
		&[data-index='0'] {
			background-color: ${({ bgColor }) => (bgColor ? bgColor : '#d1d1d1')};
		}
		&[data-index='1'] {
			background-color: ${({ bgColor }) => (bgColor ? bgColor : '#a9a9a9')};
		}
		&[data-index='2'] {
			background-color: ${({ bgColor }) => (bgColor ? bgColor : '#808080')};
		}
		&[data-index='3'] {
			background-color: ${({ bgColor }) => (bgColor ? bgColor : '#585858')};
		}
		&[data-index='4'] {
			background-color: ${({ bgColor }) => (bgColor ? bgColor : '#2f2f2f')};
		}
	}
	&.active {
		background-color: rgba(255, 255, 255, 0.01) !important;
		@supports ((-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))) {
			backdrop-filter: blur(30px);
			background-color: rgba(255, 255, 255, 0.01) !important;
		}
		left: ${({ count, index }) => `calc(30.4% / ${count - 1} * ${index} + 25%)`};
		width: 30px;
		> button {
			transform: rotate(90deg);
			white-space: nowrap;
		}
	}
	&.animated-before {
		left: ${({ count, index }) => `calc(30.4% / ${count - 1} * ${index})`};
		width: ${({ count }) => `calc(30.4% / ${count - 1})`};
	}
	&.animated-after {
		width: ${({ count }) => `calc(30.4% / ${count - 1})`};
		left: ${({ count, index }) => `calc(69.6% + (30.4% / ${count - 1} * ${index - 1}))`};
	}
`

const Button = styled(BHButton)`
	background-color: rgba(255, 255, 255, 0.01);
	@supports ((-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))) {
		backdrop-filter: blur(30px);
		background-color: rgba(255, 255, 255, 0.01);
	}
	cursor: inherit;
	font-size: ${({ theme }) => theme.typography.pxToRem(25)};
	letter-spacing: -0.5px;
	line-height: 1;
	padding: ${({ theme }) => theme.spacing('15.5px', 5)};
	min-width: 190px;
	position: absolute;
	transition: all 1s;
	z-index: 1;
`

const VideoWrapper = styled('div', { shouldForwardProp })`
	height: 100%;
	left: ${({ count, index }) => `calc(100% / ${count} * ${index})`};
	position: absolute;
	top: 0;
	transition: left 1s;
	width: 44.6%;
	&.active {
		left: ${({ count, index }) => `calc(30.4% / ${count - 1} * ${index} + 25%)`};
	}
	&.animated-after {
		left: ${({ count, index }) => `calc(69.6% + (30.4% / ${count - 1} * (${index - 1})))`};
	}
	.play-icon {
		display: none;
	}
`

const TextWrapper = styled(MuiStack, { shouldForwardProp })`
	background-color: #e8e8e8;
	height: 100%;
	left: ${({ count, index }) => `calc(100% / ${count} * ${index})`};
	overflow: hidden;
	padding: ${({ theme }) => theme.spacing(0, 4)};
	position: absolute;
	top: 0;
	transition: all 1s;
	width: calc(100% / ${({ count }) => count});
	z-index: 1;
	&.active {
		left: ${({ count, index }) => `calc(30.4% / ${count - 1} * ${index})`};
		width: 25%;
		z-index: 2;
	}
	&.animated-before {
		left: -25%;
	}
	&.animated-after {
		opacity: 1;
		${({ count, index }) => (index === 0 ? null : `transform:translateX(calc(100% * (${count - index})))`)};
	}
`

export { Button, CtaWrapper, ImageWrapper, TextWrapper, VideoWrapper }
