import { useRef, useState } from 'react'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import Image from '@bluheadless/ui/src/particles/image'
import Video from '@bluheadless/ui/src/particles/video'
import Link from '@bluheadless/ui/src/molecules/link'
import Typography from '@bluheadless/ui/src/atoms/typography'
import { Button, CtaWrapper, ImageWrapper, TextWrapper, VideoWrapper } from './slice.styled'
import { CtasWrapper, Description, Subtitle } from './slider-accordion.styled'

const Slice = ({
	bgColor,
	content,
	count,
	ctas,
	imageData,
	index,
	onClick,
	subtitle,
	subtitleTag,
	title,
	video,
	videoConfig,
}) => {
	const [playing, setPlaying] = useState(false)
	const ctaRef = useRef()

	const links = useLinks([
		{ type: ctas[0]?.linkType, href: ctas[0]?.link },
		{ type: ctas[1]?.linkType, href: ctas[1]?.link },
		{ type: ctas[2]?.linkType, href: ctas[2]?.link },
	])

	const { siteName } = useConfig()

	let getSiblings = function (e) {
		// for collecting siblings
		let siblings = []
		// if no parent, return no sibling
		if (!e.parentNode) {
			return siblings
		}
		// first child of the parent node
		let sibling = e.parentNode.firstChild

		// collecting siblings
		while (sibling) {
			if (sibling.nodeType === 1 && sibling !== e) {
				siblings.push(sibling)
			}
			sibling = sibling.nextSibling
		}
		return siblings
	}

	const expand = (index) => {
		if (ctaRef.current && !ctaRef.current.classList.contains('active')) {
			const siblings = getSiblings(ctaRef.current)

			if (ctaRef.current.classList.contains('animated-after')) {
				ctaRef.current.classList.remove('animated-after')
			}

			if (ctaRef.current.classList.contains('animated-before')) {
				ctaRef.current.classList.remove('animated-before')
			}

			ctaRef.current.classList.add('active')

			siblings.forEach((item) => {
				const itemIndex = parseInt(item.getAttribute('data-index'))

				if (itemIndex < index) {
					if (item.classList.contains('active')) {
						item.style.removeProperty('z-index')
						item.classList.remove('active')
					}

					if (item.classList.contains('animated-after')) {
						item.classList.remove('animated-after')
					}

					if (!item.classList.contains('animated-before')) {
						item.classList.add('animated-before')
					}
				} else if (itemIndex === index) {
					if (item.classList.contains('animated-after')) {
						item.classList.remove('animated-after')
					}

					if (item.classList.contains('animated-before')) {
						item.classList.remove('animated-before')
					}

					if (item.querySelector('video')) {
						item.style['z-index'] = 1
					}

					item.classList.add('active')
				} else {
					if (item.classList.contains('active')) {
						item.classList.remove('active')
					}

					if (item.classList.contains('animated-before')) {
						item.classList.remove('animated-before')
					}

					if (!item.classList.contains('animated-after')) {
						item.classList.add('animated-after')
					}
				}
			})

			if (!playing) {
				setPlaying(true)
			}
		}
	}

	return (
		<>
			<TextWrapper count={count} data-index={index} direction="column" index={index} justifyContent="center">
				<Subtitle color="primary" component={subtitleTag}>
					{subtitle}
				</Subtitle>

				<Description content={content} />

				{links?.length ? (
					<CtasWrapper direction="row" spacing={5}>
						{links.map((value, i) => (
							<Link href={value} key={i}>
								<Typography component="h3" variant="body2">
									{ctas[i]?.text}
								</Typography>
							</Link>
						))}
					</CtasWrapper>
				) : null}
			</TextWrapper>

			<ImageWrapper count={count} data-index={index} index={index}>
				<Image
					alt={title + ' - ' + siteName}
					originalHeight={imageData.originalHeight}
					originalWidth={imageData.originalWidth}
					src={imageData.src}
				/>
			</ImageWrapper>

			<CtaWrapper
				bgColor={bgColor}
				count={count}
				data-index={index}
				index={index}
				onClick={() => {
					expand(index)
				}}
				onMouseEnter={(e) => {
					if (
						!e.target.classList.contains('active') &&
						!e.target.classList.contains('animated-before') &&
						!e.target.classList.contains('animated-after') &&
						!playing
					) {
						const nextElement = e.target.nextSibling

						if (nextElement) {
							nextElement.style['z-index'] = 1
						}
						setPlaying(true)
					}
				}}
				onMouseLeave={(e) => {
					if (
						e.target.type !== 'button' &&
						!e.target.classList.contains('active') &&
						!e.target.classList.contains('animated-before') &&
						!e.target.classList.contains('animated-after') &&
						playing
					) {
						const nextElement = e.target.nextSibling

						if (nextElement) {
							nextElement.style['z-index'] = 0
						}
						setPlaying(false)
					}
				}}
				ref={ctaRef}
			>
				<Button onClick={onClick}>{title}</Button>
			</CtaWrapper>

			<VideoWrapper count={count} data-index={index} index={index}>
				<Video
					{...video}
					{...videoConfig}
					autoplay={false}
					controls={false}
					count={count}
					index={index}
					playing={playing}
					startOnEveryInView={false}
				/>
			</VideoWrapper>
		</>
	)
}

export default Slice
