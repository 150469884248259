import { useTheme } from '@mui/system'
import { useMediaQuery } from '@mui/material'
import { useState } from 'react'
import Slice from './slice'
import AccordionWrapper from './accordion-wrapper'

const SliderAccordion = ({ children }) => {
	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))

	const [expanded, setExpanded] = useState(false)

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	return children?.length
		? children.map(({ props }, index) => {
				const { data: slideData } = props
				return mdUp ? (
					<Slice
						bgColor={slideData.backgroundColor}
						content={slideData.content}
						count={children.length}
						ctas={slideData.ctas}
						imageData={slideData.image.desktop}
						index={index}
						key={index}
						subtitle={slideData.subtitle}
						subtitleTag={slideData.subtitleTag}
						title={slideData.title}
						titleTag={slideData.titleTag}
						video={slideData.video.desktop}
						videoConfig={slideData.videoConfig}
					/>
				) : (
					<AccordionWrapper
						bgImage={slideData.image.mobile}
						content={slideData.content}
						ctas={slideData.ctas}
						expanded={expanded}
						handleChange={handleChange}
						index={index}
						key={index}
						subtitle={slideData.subtitle}
						subtitleTag={slideData.subtitleTag}
						title={slideData.title}
						video={slideData.video.mobile}
					/>
				)
		  })
		: null
}

export default SliderAccordion
